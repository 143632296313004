import {
  Box,
  Button,
  Paper,
  Switch,
  Typography,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getConfigs, updateConfigs, generateApiKey } from 'apis/settings';
import { useSnackbar } from 'contexts/snackbar-context';
import { useUser } from 'contexts/user-context';
import { FC, useEffect, useState } from 'react';
import { deepCopy } from 'utils';
import ReactJson from 'react-json-view';

export type ConfigItemType = {
  group: string;
  field: string;
  value: any;
};

type SelectorFieldMap = {
  [key: string]: {
    options: { label: string; value: string }[];
  };
};

const SELECTOR_FIELD_MAP: SelectorFieldMap = {
  PAGE_STATUS_SPIDER_CRAWL_FREQUENCY: {
    options: [
      { label: 'Manual', value: 'manual' },
      { label: 'Hourly', value: 'hourly' },
      { label: 'Daily', value: 'daily' },
      { label: 'Weekly', value: 'weekly' },
      { label: 'Monthly', value: 'monthly' },
    ],
  },
};

type FieldType = 'string' | 'boolean' | 'selector' | 'json';

export const ConfigItem: FC<
  ConfigItemType & { onChange: (item: ConfigItemType) => void }
> = ({ group, field, value, onChange }) => {
  const queryClient = useQueryClient();
  const { setSnackbar } = useSnackbar();
  const { curIndex } = useUser();
  // JsonView / TextView for Json config
  const [isJsonView, setIsJsonView] = useState(true);
  let fieldType: FieldType = 'string';
  if (field in SELECTOR_FIELD_MAP) fieldType = 'selector';
  if (value === 'True') value = true;
  if (value === 'False') value = false;
  if (typeof value === 'boolean') {
    fieldType = 'boolean';
  }
  let jsonValue = {};
  if (field.endsWith('_CONFIG')) {
    fieldType = 'json';
    try {
      jsonValue = JSON.parse(value);
    } catch (err: any) {
      jsonValue = {};
    }
  }

  const handleSave = async () => {
    const item: ConfigItemType = { group, value, field };
    if (fieldType === 'json') {
      try {
        jsonValue = JSON.parse(value);
      } catch (err: any) {
        setSnackbar({ severity: 'error', message: 'Invalid Json Object.' });
        return;
      }
    }
    try {
      await updateConfigs(curIndex!.value, [item]);
      setSnackbar({ severity: 'success', message: 'Saved' });
    } catch (err: any) {
      if (err?.response?.status === 403) {
        const msg = err.response.data?.errMsg;
        setSnackbar({
          severity: 'error',
          message: msg || 'Only admin can update.',
        });
      }
    }
  };

  const handleGenerateSave = async () => {
    const item: ConfigItemType = { group, value, field };
    try {
      const apiData = await generateApiKey();
      item.value = apiData.api_key;
      await updateConfigs(curIndex!.value, [item]);
      setSnackbar({ severity: 'success', message: 'Saved' });
      queryClient.invalidateQueries([curIndex, 'IndexConfigs']);
    } catch (err: any) {
      if (err?.response?.status === 403) {
        const msg = err.response.data?.errMsg;
        setSnackbar({
          severity: 'error',
          message: msg || 'Only admin can update.',
        });
      }
    }
  };

  const handleSwitch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const item: ConfigItemType = { group, value: checked, field };
    onChange(item);
    try {
      await updateConfigs(curIndex!.value, [item]);
      setSnackbar({ severity: 'success', message: 'Saved' });
      queryClient.invalidateQueries([curIndex, 'IndexFields']);
    } catch (err: any) {
      if (err?.response?.status === 403) {
        const msg = err.response.data?.errMsg;
        setSnackbar({
          severity: 'error',
          message: msg || 'Only admin can update.',
        });
      }
    }
  };

  return (
    <Box>
      {fieldType !== 'boolean' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'flex-start',
          }}
        >
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Typography variant="caption">{field}</Typography>
            {fieldType === 'json' && (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isJsonView}
                    onChange={(e) => setIsJsonView(e.target.checked)}
                  />
                }
                label="JSON View"
              />
            )}
          </Box>
          {fieldType === 'selector' && (
            <TextField
              size="small"
              select
              value={(value as string) ?? ''}
              defaultValue={(value as string) ?? ''}
              onChange={(e) =>
                onChange({ group, field, value: e.target.value })
              }
              sx={{ minWidth: 80 }}
            >
              {SELECTOR_FIELD_MAP[field].options.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          {(fieldType === 'string' || !isJsonView) && (
            <TextareaAutosize
              placeholder={field}
              value={(value as string) ?? ''}
              onChange={(e) =>
                onChange({ group, field, value: e.target.value })
              }
            />
          )}
          {fieldType === 'json' && isJsonView && (
            <>
              {/*  @ts-ignore */}
              <ReactJson name={null} src={jsonValue || {}} />
            </>
          )}
          {field === 'X-API-KEY' && (
            <Button onClick={handleGenerateSave}>Generate & Save</Button>
          )}
          {field !== 'X-API-KEY' && <Button onClick={handleSave}>Save</Button>}
        </Box>
      )}
      {fieldType === 'boolean' && (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Typography variant="body2">{field + ':'}</Typography>
          <Switch checked={value as boolean} onChange={handleSwitch} />
        </Box>
      )}
    </Box>
  );
};

const SettingsConfig: FC = () => {
  const { curIndex } = useUser();
  const { data: configData } = useQuery(
    [curIndex, 'IndexConfigs'],
    () => getConfigs(curIndex!.value),
    { enabled: Boolean(curIndex) }
  );

  const [items, setItems] = useState<ConfigItemType[]>([]);
  const handleChange = (item: ConfigItemType) => {
    const idx = items.findIndex(
      (o) => o.field === item.field && o.group === item.group
    );
    if (idx !== -1) {
      const _items = deepCopy(items) as ConfigItemType[];
      _items[idx].value = item.value;
      setItems(_items);
    }
  };

  useEffect(() => {
    if (configData) {
      const _items: ConfigItemType[] = [];
      for (const group of configData) {
        const groupName = group.group_name;
        for (const obj of group.settings_arr) {
          const key = Object.keys(obj)[0];
          const val = obj[key];
          _items.push({ group: groupName, field: key, value: val as string });
        }
      }
      setItems(_items);
    }
  }, [configData]);

  const itemsGeneric = items.filter((item: any) => item.group === 'Generic');
  const itemsIngredient = items.filter(
    (item: any) => item.group === 'Ingredient'
  );
  const itemsSpider = items.filter((item: any) => item.group === 'Spider');
  const itemsSystem = items.filter((item: any) => item.group === 'System');
  const itemsSearchSpider = items.filter(
    (item: any) => item.group === 'Search Spider'
  );

  const itemGroups = [
    { key: 'General', value: itemsGeneric },
    { key: 'Ingredient limit', value: itemsIngredient },
    { key: 'Spider', value: itemsSpider },
    { key: 'Search spider', value: itemsSearchSpider },
    { key: 'System', value: itemsSystem },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {itemGroups.map((group) => (
        <Paper
          key={group.key}
          sx={{
            p: 2,
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            rowGap: 4,
          }}
        >
          <Typography sx={{ gridColumn: '1 / span 3' }} variant="h6">
            {group.key}
          </Typography>
          {group.value.map((item) => (
            <ConfigItem
              key={item.group + item.field}
              group={item.group}
              field={item.field}
              value={item.value}
              onChange={handleChange}
            />
          ))}
        </Paper>
      ))}
    </Box>
  );
};

export default SettingsConfig;
